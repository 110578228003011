<template>
  <div class="main">
    <vue-masonry-wall v-if="selectedItems.length > 0" :items="selectedItems"
    :options="options" @append="loadMore">
      <template v-slot:default="{item}">
        <div class="Item">
          <img :src="getImageUrl(item.name)" />

          <div class="Content">
            <h5 class="text-ellipsis-1l">{{item.title}}</h5>
            <p class="text-ellipsis-2l">{{item.content}}</p>
          </div>
        </div>
      </template>
    </vue-masonry-wall>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  components: {},
  data() {
    return {
      baseUrl: 'https://firebasestorage.googleapis.com/v0/b/marcrufeis-photography.appspot.com/o/',
      items: [],
      selectedItems: [],
      options: {
        width: 500,
        padding: {
          2: 8,
          default: 12,
        },
      },
    };
  },
  methods: {
    getData() {
      axios
        .get(this.baseUrl)
        .then((res) => {
          this.items = res.data.items;
          this.loadMore();
        });
    },
    loadMore() {
      const rand = Math.floor(Math.random() * this.items.length);
      this.selectedItems.push(this.items[rand]);
    },
    getImageUrl(name) {
      return `${this.baseUrl}${name}?alt=media`;
    },
  },
  created() {
    this.getData();
  },
};

</script>

<style>
  .main {
    width: calc(100vw - (20px));
  }

  .Item {
    overflow: hidden;
    border-radius: 4px;
    width: 100%;
    background: #F5F5F5;
    position: relative;
  }

  .Content {
    transition: 0.3s;
    color: white;
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    padding: 20px;
    height: 100%;
    width: 100%;
    background: rgba(10, 10, 10, 0.8);
  }
  .Content:hover{
    /*opacity: 100;*/
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    line-height: 0;
    display: block;
  }

</style>
