import Vue from 'vue';
import MasonryWall from 'vue-masonry-wall';
import App from './App.vue';

Vue.use(MasonryWall);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');
